.dx-datagrid-headers {
  color: $fontColorDefault;
  font-weight: bold;
  .dx-datagrid-table {
    .dx-row {
      td {
        font-size: 1rem;
        font-weight: bold;
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: #e5e5e51f;
      }
    }
  }
  .dx-datagrid-rowsview {
    .dx-select-checkboxes-hidden {
      .dx-select-checkbox {
        display: block;
      }
    }
  }
}

.grid-action-hover.hidden {
  position: absolute;
  right: 0;
  padding: 4px 10px !important;
  border: 0 !important;
  box-shadow: none !important;
  height: 36px;
  min-height: 36px !important;
  margin-top: 1px;
}

.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
  .grid-action-hover:not(.hidden) {
    position: sticky;
    right: 0;
    padding: 0 10px;
    background-color: transparent;
    border-left: 0 !important;
    & > *:first-child {
      display: none;
    }
  }
}

.dx-texteditor-input {
  font-size: 14px;
  font-weight: 500;
}

.dx-datagrid-headers {
  dx-datagrid-table .dx-row > td {
    border-bottom: none;
  }

  .dx-header-row td {
    //text-align: center !important;
    font-weight: bold;
    background-color: $dataGridSelectedBgColor !important;
    //font-weight: 700;
    //background-color: white !important;
    border-bottom: none;
    overflow-y: visible;
    .dx-datagrid-text-content, .dx-sort-indicator{
      color: #3E4050 !important;
      //font-weight: 200;
    }
    .dx-datagrid .dx-sort-down:before{
      font-weight: 200;
    }
  }
}

.dx-datagrid-content {
  position: static;
  .dx-datagrid-table {
    .dx-row {
      &.dx-state-hover {
        .grid-action-hover {
          & > *:first-child {
            display: block !important;
          }

          &.hidden {
            display: block !important;
          }
        }
      }
      .dx-editor-cell {
        padding: 0 !important;
      }
    }
  }
}

.dx-datagrid .dx-row > td {
  background-color: $dataGridBgEvenColor;
}
.dx-datagrid .dx-row-alt > td {
  background-color: $dataGridBgEvenColor;
}
.dx-datagrid-rowsview .dx-selection.dx-row > td, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
  background-color: $dataGridSelectedBgColor;
  color: $dataGridSelectedFontColor;
  a {
    color: $dataGridSelectedFontColor !important;
  }
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-edit.dx-command-edit-with-icons .dx-link, .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link, .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link {
  color: $dataGridActionButtonColor;
}
.dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: $primaryColor;
}
//.dx-pager .dx-pages .dx-selection, .dx-pager .dx-page-sizes .dx-selection {
//  background-color: $pagingPerPageBackground;
//  color: $pagingPerPageColor;
//}
.dx-datagrid {
  color: $dataGridCellFontColor;
}

.dx-pager .dx-pages .dx-info {
  color: $pagingInfoColor;
  opacity: 1;
}
.grid-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  background-color: $gridContainerBgColor;
  border: 1px solid $gridContainerBorderColor;
  border-right: none;
  border-left: none;
}

.dx-datagrid .dx-column-lines td {
  border-right: 1px solid rgb(236, 241, 246);

}

.grid-title {
  color: #2f78b9;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 25px;
  padding: 2px 0 0 2px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: $dataGridBgEvenColor;
  /*background-image: linear-gradient(rgb(190, 204, 216), rgb(202, 218, 222), rgb(190, 204, 216));*/
}

.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td, .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > tr > td {
  border-top: 1px solid $gridContainerBorderColor;
}

.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
  background-color: #e6e6e6;
  color: #333;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-select {
  padding-left: 4px;
}
.dx-checkbox-icon {
  border: 2px solid $black;
}
.paging {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  padding: 5px;
  min-width: 330px;
  font-weight: bold;

  .pageOf, .pagePer{
    margin-left:5px;
    margin-right:5px;
    text-align: center;
    height:25px;
    border-radius:5px;
  }
  .pagePer {
    background-color: #F5F5F5;
    &:hover, &:focus {
      background-color: $defaultColor;
    }
    select {
      padding-left: 9px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    svg {
      top: calc(50% - 11px);
    }
    fieldset {
      border: none;
    }
  }
  .pageOf {
    width: 1%;
    margin-top: 6px;
    -moz-appearance: textfield;

    .MuiInput-underline:before {
      border-bottom-width: 2px;
    }

    input {
      text-align: center;
      padding: 3px 0 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  //.pageOf::-webkit-outer-spin-button,
  //.pageOf::-webkit-inner-spin-button {
  //  -webkit-appearance: none;
  //  margin: 0;
  //}
  .first span,
  .next span,
  .prev span,
  .last span
  {
    color: $fontColorDefault;
  }
  .first, .prev, .next, .last {
    padding: 6px 8px;
    border-radius: 50%;
    min-width: 18px;
  }
  .slash {
    margin: 0 3px;
    color: $fontColorDefault;
  }
}
.dx-invalid-message .dx-overlay-wrapper .dx-overlay-content {
  float: right;
  transform: translate(1px, -35px)!important;
}

.dx-popup-content:not(.dx-visibility-change-handler .dx-popup-content){
  padding: 0!important;
}

//.dx-scrollable-container {
//  background-color: $gridContainerBgColor;
//}

.dx-datagrid .dx-row-lines > td {
  border-bottom: 1px solid $gridContainerBorderColor;
}

//update UI paging on tablet and mobile view.
@media only screen and (max-width: 960px) {
.paging-crm{

  .txt-line-number{
    display: none;
  }
  .hide-mobile, .pagePer{
    display: none;
  }
}
}
//.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper{
//  padding-bottom: 10px;
//}

.dx-datagrid{
  .dx-row{
    td{
      min-height: 37px;
      font-size: 1rem;
      vertical-align: middle!important;
    }
  }
}
.disabledSearch{
  display: none !important;
}

.dx-popup-content{
  padding:0!important;
}

.grid-full-width{
  position: relative;
  .grid-container{
    overflow-x: scroll ;
    padding-top: 38px;
    .toolbar-container{
      position: absolute;
      left: 15px;
      width: calc(100% - 30px);
      top: 0;
    }
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content),
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content) {
  padding-right: 7px;
  padding-left: 7px;
}


.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td .dx-texteditor,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content) .dx-texteditor,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content) .dx-texteditor {
  padding-right: 7px;
  padding-left: 7px;
}

.dx-datagrid .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-datagrid-container .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  color: #334BAB
}

.dx-datagrid-text-content {
  overflow: visible;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td {
   .display-action-button-w29f2005{
     visibility: hidden;
   }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row.dx-state-hover > td {
  .display-action-button-w29f2005{
    visibility: visible;
  }
}
