/** ======= Generate List Margin, Padding ======= */

/** info root spacing */
$rootSpacing: 4;
$rootSpacingOld: 5;

/** list scale */
$sizes: (
    0: 0,
    1: 1,
    2: 2,
    2dot5: 2.5,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    8: 8,
    10: 10,
);

/** list arrow */
$arrows: (
    "": "",
    l: left,
    r: right,
    t: top,
    b: bottom,
);

/**
  Return className
  @params: key {string} info arrow
  @size: key {string} info scale
  @str: key {string} info classname
 */
@function getClassName($key, $size, $str) {
    @if ($size != 0) {
        $size: #{$size}x;
    }
    @if ($key == "") {
        @return #{$str}#{$size};
    } @else {
        @return #{$str}#{$key}#{$size};
    }
}

/**
  Return className
  @params: key {string} info arrow
  @size: key {string} info scale
  @str: key {string} info classname
 */
@function getClassNameOld($key, $size, $str) {
    @return #{$str}#{$key}#{$size * $rootSpacingOld};
}

/**
  Return className
  @params: key {string} info arrow
  @str: key {string} info css
 */
@function getCssName($key, $tr) {
    @if ($key == "") {
        @return $tr;
    } @else {
        @return #{$tr}-#{$key};
    }
}

@each $size, $vlSize in $sizes {
    /* ================ Margin ================ */
    @each $key, $vl in $arrows {
        .#{getClassName($key, $size, mg)} {
            #{getCssName($vl, margin)}: #{$vlSize * $rootSpacing}px !important;
        }

        /** ======= Override Old Margin ======= */
        .#{getClassNameOld($key, $size, mg)} {
            #{getCssName($vl, margin)}: #{$vlSize * $rootSpacing}px !important;
        }
        /** ======= End Override Old Margin ======= */
    }
    .mgv#{$size}x {
        margin: #{$vlSize * $rootSpacing}px 0px !important;
    }
    .mgh#{$size}x {
        margin: 0px #{$vlSize * $rootSpacing}px !important;
    }

    /** ======= Override Old Margin ======= */
    .mgv#{$size*$rootSpacingOld} {
        margin: #{$vlSize * $rootSpacing}px 0px !important;
    }
    .mgh#{$size*$rootSpacingOld} {
        margin: 0px #{$vlSize * $rootSpacing}px !important;
    }
    /** ======= End Override Old Margin ======= */

    /* ================ Padding ================ */
    @each $key, $vl in $arrows {
        .#{getClassName($key, $size, pd)} {
            #{getCssName($vl, padding)}: #{$vlSize * $rootSpacing}px !important;
        }

        /** ======= Override Old Padding ======= */
        .#{getClassNameOld($key, $size, pd)} {
            #{getCssName($vl, padding)}: #{$vlSize * $rootSpacing}px !important;
        }
        /** ======= End Override Old Padding ======= */
    }
    .pdv#{$size}x {
        padding: #{$vlSize * $rootSpacing}px 0px !important;
    }
    .pdh#{$size}x {
        padding: 0px #{$vlSize * $rootSpacing}px !important;
    }

    /** ======= Override Old Padding ======= */
    .pdv#{$size*$rootSpacingOld} {
        padding: #{$vlSize * $rootSpacing}px 0px !important;
    }
    .pdh#{$size*$rootSpacingOld} {
        padding: 0px #{$vlSize * $rootSpacing}px !important;
    }
    /** ======= End Override Old Padding ======= */
}

/** ================ Result ================
    mg0, mg1x, mg2x...... margin: <tỉ lệ>x4px;
    mgl0, mgl1x, mgl2x...... margin-left: <tỉ lệ>x4px;
    mgv0, mgv1x, mgl2x...... margin: <tỉ lệ>x4px 0px;
    mgh0, mgh1x, mgh2x...... margin: 0px <tỉ lệ>x4px ;
    tương tự cho padding: pd0, pdl0, pdl1x
*/
