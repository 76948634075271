.dx-list {
  border-left: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0 0 3px 3px;

}

.group-list-fixed{
  height: calc(100% - 27px);
  overflow-y: scroll;
  padding-top: 15px;
}

.list-custom{
  border: 1px solid #d6d6d6;
  border-width: 0 1px 1px;
  background-color: #f8f8f8;
  height: 100%;
  .custom-list-item{
    padding: 5px 10px !important;
    background-color: white;
    white-space: normal;
    cursor: pointer;
    margin-bottom: 0;
    border-bottom: 1px solid #d6d6d6;
    border-radius: 0 !important;
    .fa-paperclip{
      color: grey;
      margin: 0 0 0 10px !important;
    }
    .priority-view{
      width: auto !important;
      min-height: 15px;
      white-space: pre;
    }
    .txt-date-time{
      border: 1px solid;
      display: flex;
      align-items: center;
    }
    .txt-date-time, .priority-view{
      height:15px;
      text-align: center;
      font-size: 10px;
      line-height: 15px;
      color:antiquewhite;
      margin-left: 5px;
      padding: 0 10px;
      border-radius: 100px;
      i{
        margin-right: 5px;
        line-height: 12px;
      }
    }
    &:hover{
      background-color: #f5f5f5;
    }
    .wth100{
      font-size: 14px;
      //color: rgb(51, 122, 183);
      line-height: 20px;
      min-height: 20px;
      margin-bottom: 5px;
      display: block;
    }
    span{
      line-height: 20px;
      min-height: 20px;
      width: 100%;
      text-align: left;
    }
    button{
      font-size: 12px;
      padding: 0;
    }
    img{
      width: 25px;
      height: 25px;
      margin-left: 10px;
      border-radius: 100px;
    }
    .fas{
      margin-right: 6px;
      font-size: 12px;
      width: 12px;
      height: 12px;
    }
    .fa-star{
      margin-right: 0 !important;
      font-size: 10px;
      width: 14px;
      height: 14px;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: $defaultColor;
  color: $fontColorDefault;
}

.dx-treelist .dx-row-lines > td {
  border-bottom: none!important;
}

.dx-list-item-content {
  //padding: 0 0 10px 0 !important;
  white-space: normal;
  //border-bottom: 1px solid #d6d6d6;
}

.dx-list-item:first-of-type{
  margin-top: 0;
}

.list_padding {
  margin-right: 0!important;
  margin-left: 0!important;
}

.list_padding >div{
  padding-right: 5px!important;
  padding-left: 5px!important;

}

.dx-list-item-content {
  .form-group{
  margin-bottom: 5px!important;
}}

.combo_padding{
  padding-left: 10px!important;
}

//.dx-empty-message {
//  color: transparent!important;
//}

.list-container{
  .dx-list-item {
    border-top: none;
  }
  .fc-header-toolbar{
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 !important;
  }
  .fc-right{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span{
      width:14px;
      height:14px;
      border-radius: 7px;
      margin: 0 5px;
      display: block;
    }
  }
}