/**
* @copyright 2019 @ DigiNet
* @author TRIHAO
* @create 11/8/2019
* @Example
*/

.MuiDialog-paperFullWidth.MuiDialog-paper {
  margin: 15px;
  width: calc(100% - 30px);
  max-height: calc(100% - 30px);
}

.MuiDialogTitle-root {
  padding: 5px 15px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    color: grey;
    z-index: 2000;
    padding: 6px;
  }
}

.MuiDialogContent-dividers {
  padding-top: 5px !important;
}
